const testMode = false
let host
let dir_api_name
let appVersion

if(testMode){
    host = "https://test.santascarnitas.com/"
    dir_api_name = "API"
    appVersion = "4.2b"
}else{
    host = "https://precompra.santascarnitas.com/"
    dir_api_name = "APIv4.2"
    appVersion = "1.2.7.1"
}

const currencyCountry = "de-AT"
const styleCurrency = { style: "currency", currency: "USD" }

export { host, dir_api_name, testMode, appVersion, currencyCountry, styleCurrency } 