import React, { useEffect,Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';


import Header from '../Components/header';
import Footer from '../Components/Footer';
import CheckVersion from '../Util/checkVersion';
import { host, dir_api_name, testMode } from '../api_vars';
import { fetchSession } from '../Redux/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Body } from './../Body/Style/BodyStyle'
import './../dist/css/main.min.css'
import App from '../Components/App';


const API_Cart = React.lazy(() => import('../Components/API_Cart'));
const Login = React.lazy(() => import('../Components/Login'));
const Carrito = React.lazy(() => import('../Components/Carrito'));
const Profile = React.lazy(() => import('../Components/Profile'))
const SignIn = React.lazy(() => import('../Components/SignIn'))
const RecoveryPassword = React.lazy(() => import('../Components/recoveryPassword'))
const EmailConfirmation = React.lazy(() => import('../Components/EmailConfirmation'))
const CreateRecoveryPassword = React.lazy(() => import('../Components/createRecoveryPassword'))
const Terms = React.lazy(() => import('../Components/Terms'))
const Summary = React.lazy(() => import('../Components/Summary'))

const Routes = () => {
    
    const dispatch = useDispatch()

    const session = useSelector(state => state.user.session)

    useEffect(() => {
        if (localStorage.getItem("session")) {

            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers.append('Origin', host);
            let miInit = {
                method: 'POST',
                headers: headers,
                mode: 'cors'
            };


            fetch(host + dir_api_name + `/shop/components/users/auth/sessionCheck.php`, miInit)

                .then((res) => {

                    if (res.ok)

                        return res.json()

                    else

                        throw res

                })
                .then((res) => {

                    if (res.session === false && !testMode) {

                        dispatch(fetchSession({ username: "", token: "", userData: "", logged: false }))

                        console.log(res)

                    } else {

                        dispatch(fetchSession({ ...JSON.parse(localStorage.getItem("session")), logged: true }))

                    }

                })
                .catch((err) => {
                    console.error(err)
                    // window.location.href = "/login/"
                })
        } else {
            console.log("Session local host is not defined")
            dispatch(fetchSession({ logged: false }))
        }
    }, [])

    const fetchUser = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);
        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(`${host}${dir_api_name}/shop/components/profile/info/api_consult_user_info.php`, miInit).then((response) => {

            if (response.ok) {
                return response.json();
            }

            else { throw response };

        })
    }

    useEffect(() => {

        if (session.logged && !testMode) {
            fetchUser()
                .then((res) => {
                    dispatch(fetchSession({
                        username: res[0].username,
                        email: res[0].email,
                        userData: JSON.parse(res[0].userData)
                    }))
                }).catch(err => {

                    alert(`Error cargando datos ref: ${err}`)

                });
        }
    }, [session.logged])
    
    return (
        <BrowserRouter>
            <Header showCatalogue={true} />
            <CheckVersion />
            <Body>

                <Suspense fallback={<div>Loading ...</div>}>

                    <Switch>

                        <Route exact path="/">
                            <App />
                        </Route>

                        <Route path="/Registro/:id" component={API_Cart}></Route>

                        <Route path="/Login/" component={Login}></Route>

                        <Route path="/tienda/" component={App}></Route>

                        <Route path="/busqueda/:search" component={App}></Route>

                        <Route path="/carrito/" component={Carrito}></Route>

                        <Route path="/perfil/" component={Profile}></Route>

                        <Route path="/registro/" component={SignIn}></Route>

                        <Route path="/recuperacion/:key" component={RecoveryPassword}></Route>

                        <Route path="/recuperacion/" component={CreateRecoveryPassword}></Route>

                        <Route path="/confirmacion_correo/:key" component={EmailConfirmation}></Route>

                        <Route path="/politicas/" component={Terms}></Route>

                        <Route path="/resumen/" component={Summary}></Route>

                    </Switch>

                </Suspense>
            </Body>
            <Footer />
        </BrowserRouter>
    )

}


export default Routes