const combosPropsVer2 = [
    {
        title: "KIT TARDE DE AMIGOS",
        collapseStatus: 'open',
        price: 42990,
        id: "cm1",
        ver: 2,
        content: [
            {
                id: "1",
                description: "Empanada de carne",
                lines: "RES",
                quantity: "20",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "2",
                description: "Nuggets de pollo",
                lines: "res",
                quantity: "300",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "3",
                description: "Chorizo cardisso",
                lines: "res",
                quantity: "250",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "4",
                description: "Chimichurri SC 130 cc",
                lines: "CERDO",
                quantity: "130 CC",
                unit: "",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "5",
                description: "Salsa BBQ Maple",
                lines: "res",
                quantity: "165",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "6",
                description: "Aripetas la troja",
                lines: "res",
                quantity: "10",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            }
        ]
    },
    {
        title: "KIT PARRILLERO",
        collapseStatus: 'open',
        price: 61990,
        id: "cm2",
        ver: 2,
        content: [
            {
                id: "1",
                description: "Milanesa de res x 8 porciones",
                lines: "RES",
                quantity: "800",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "2",
                description: "Carne de cerdo x 8 porciones",
                lines: "cerdo",
                quantity: "800",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "3",
                description: "5 tiras de chicharron",
                lines: "res",
                quantity: "500",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "4",
                description: "Chorizo de cerdo SC",
                lines: "CERDO",
                quantity: "10",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "5",
                description: "Arepitas",
                lines: "Antojos",
                quantity: "10",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },
            {
                id: "6",
                description: "Chimichurri sc",
                lines: "res",
                quantity: "230",
                unit: "CC",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            }
        ]
    },{
        title: "KIT MADRES",
        collapseStatus: 'open',
        price: 26800,
        id:"cm3",
        ver: 2,
        content: [
            {
                id: "1",
                description: "Cañón relleno de tocineta, Jamón y queso",
                lines: "RES",
                quantity: "1",
                unit: "KILO",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "2",
                description: "Salsa ciruela 100g",
                lines: "ANTOJOS",
                quantity: "",
                unit: "",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            }
        ]
    },{
        title: "KIT ANTIOQUEÑO",
        collapseStatus: 'open',
        price: 45000,
        id:"cm4",
        ver: 2,
        content: [
            {
                id: "1",
                description: "Milanesa de res en Goulash",
                lines: "RES",
                quantity: "500",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "2",
                description: "Costilla de Cerdo Picada",
                lines: "CERDO",
                quantity: "500",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "3",
                description: "Tocino Barriguero en Goulash",
                lines: "CERDO",
                quantity: "500",
                unit: "GRAMOS",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "4",
                description: "Chorizo de Res SC",
                lines: "RES",
                quantity: "5",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "5",
                description: "Arepitas",
                lines: "ANTOJOS",
                quantity: "10",
                unit: "UNIDADES",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            },{
                id: "6",
                description: "Chimichurri SC",
                lines: "ANTOJOS",
                quantity: "130",
                unit: "CC",
                price: "",
                finalPrice: "",
                TypeOfCut: "",
                note: ""
            }
            
        ]
    }
]

export { combosPropsVer2 }